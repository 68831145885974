import { $$ } from '../helpers/query-selector';

const animatedCols = $$('.js-animation');
const startAnimationClass = 'animation-start';

function init() {
  for (const animatedCol of animatedCols) {
    const isFirstLayout = animatedCol.classList.contains('js-animation-first');
    const animatedItems = $$(
      '.js-animation-text, .js-animation-media',
      animatedCol
    );

    const animationObserver = new IntersectionObserver(
      (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            animatedItems.forEach((el, i) => {
              el.classList.add(startAnimationClass);
              el.style.setProperty('--delay', i * 0.1 + 's');
            });
            observer.unobserve(animatedCol);
          }
        }
      },
      { rootMargin: `0% 0% ${isFirstLayout ? 0 : -15}% 0%` }
    );
    animationObserver.observe(animatedCol);
  }
}

export default init;
