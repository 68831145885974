import animateScrollTo from 'animated-scroll-to';
import { $, $$ } from '../helpers/query-selector';
import { reducedMotion, viewport } from '../helpers/variables';

const links = $$('.js-anchor-nav a');

function init() {
  for (const link of links) {
    link.addEventListener('click', e => {
      e.preventDefault();

      const url = new URL(e.target.href);
      const target = $(url.hash);

      const options = {
        maxDuration: reducedMotion.matches ? 0 : 1500,
        speed: 500,
        easing: t => {
          return --t * t * t + 1;
        },
        verticalOffset: viewport.s.matches ? -120 : -90
      };

      animateScrollTo(target, options).then(hasScrolledToPosition => {
        if (hasScrolledToPosition) {
          target.setAttribute('tabindex', '-1');
          target.classList.add('focus-hidden');
          target.focus({ preventScroll: true });
          target.removeAttribute('tabindex');

          history.replaceState(null, null, url.hash);
        }
      });
    });
  }
}

export default init;
