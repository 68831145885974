import '../scss/app.scss';

import initLazyLoad from './modules/lazyload';
import initSetVh from './modules/set-vh';
import initNav from './modules/nav';
import initHeader from './modules/header';
import initAnchorNav from './modules/anchor-nav';
import initAnimation from './modules/animation';
import { $ } from './helpers/query-selector';

async function init() {
  const polyfills = [];

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import('intersection-observer'));
  }

  await Promise.all(polyfills);

  // Init modules that depend on polyfills
  initLazyLoad();
  initSetVh();
  initNav();
  initHeader();
  initAnchorNav();
  initAnimation();

  // Import less important modules async
  if ($('.js-hubspot-form'))
    import('./modules/hubspot-form').then(m => m.default());

  if ($('.js-form')) import('./modules/form').then(m => m.default());

  if ($('.js-filter')) import('./modules/filter').then(m => m.default());

  if ($('.js-slideshow')) import('./modules/slideshow').then(m => m.default());

  if ($('.js-carousel')) import('./modules/carousel').then(m => m.default());

  if ($('.js-floating-button'))
    import('./modules/floating-button').then(m => m.default());

  if ($('.js-lottie')) import('./modules/lottie').then(m => m.default());

  if ($('.js-dialog')) import('./modules/dialog').then(m => m.default());

  if ($('.js-quotes-slider'))
    import('./modules/quotes-slider').then(m => m.default());
}

init();
