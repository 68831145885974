import { $ } from '../helpers/query-selector';
import { reducedMotion, viewport } from '../helpers/variables';
import { navVisible } from './nav/mobile';

const main = $('.js-main');
const header = $('.js-header');
const logoAnchor = $('.js-header-logo');
const logo = $('.js-logo');
const animateToIcon = $('#js-logo-icon');
const animateToWordmark = $('#js-logo-wordmark');

const hideHeaderClass = 'l-header--hidden';
const shadowHeaderClass = 'l-header--shadow';
const shrinkedClass = 'c-logo--shrinked';
const reducedClickareaClass = 'l-header__logo--clickarea';

let hideHeaderPossible = false;
let headerHidden = false;
let logoAnimationTimer;
let reducedClickareaTimer;

function hideHeader() {
  if (headerHidden) return;
  headerHidden = true;
  header.classList.add(hideHeaderClass);

  // Start animation from "u" to "icon" with delay
  if (logoAnimationTimer) clearTimeout(logoAnimationTimer);

  logo.classList.add(shrinkedClass);

  logoAnimationTimer = setTimeout(() => {
    animateToIcon.beginElement();
  }, 275);

  reducedClickareaTimer = setTimeout(() => {
    logoAnchor.classList.add(reducedClickareaClass);
  }, 500);
}

function showHeader() {
  if (!headerHidden) return;
  headerHidden = false;
  header.classList.remove(hideHeaderClass);

  // Start animation from "icon" to "u"
  if (logoAnimationTimer) clearTimeout(logoAnimationTimer);
  if (reducedClickareaTimer) clearTimeout(reducedClickareaTimer);

  logoAnchor.classList.remove(reducedClickareaClass);

  const delay = viewport.l.matches ? 0 : 500;

  logoAnimationTimer = setTimeout(() => {
    logo.classList.remove(shrinkedClass);
    animateToWordmark.beginElement();
  }, delay);
}

// Detect scroll-behavior
let previousScrollPosition = 0;

function isScrollingDown() {
  let scrolledPosition = window.pageYOffset;
  let isScrollDown;

  if (scrolledPosition > previousScrollPosition) {
    isScrollDown = true;
  } else {
    isScrollDown = false;
  }
  previousScrollPosition = scrolledPosition;
  return isScrollDown;
}

function handleNavScroll() {
  if (navVisible || reducedMotion.matches || !hideHeaderPossible) return;

  const scrollDown = isScrollingDown();

  if (scrollDown && !headerHidden) {
    hideHeader();
  } else if (!scrollDown && headerHidden) {
    showHeader();
  }
}

let throttleTimer;

const throttle = (callback, time) => {
  if (throttleTimer) return;

  throttleTimer = true;
  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

function init() {
  window.addEventListener('scroll', () => {
    throttle(handleNavScroll, 250);
  });

  // Detect if Header can be hidden
  const headerObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          hideHeaderPossible = true;
          header.classList.add(shadowHeaderClass);
        } else {
          hideHeaderPossible = false;
          showHeader();
          header.classList.remove(shadowHeaderClass);
        }
      }
    },
    { rootMargin: '0% 0% -100% 0%' }
  );
  headerObserver.observe(main);

  logoAnchor.addEventListener('click', e => {
    if (headerHidden && viewport.l.matches) {
      e.preventDefault();
      showHeader();
    }
  });
}

export default init;
