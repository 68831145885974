import { $, $$ } from '../../helpers/query-selector';
import { hideNav } from './mobile';
import { reducedMotion, viewport } from '../../helpers/variables';
import animateScrollTo from 'animated-scroll-to';

const navAnchors = $$('.js-nav-anchor');

// Mobile & desktop
function init() {
  for (const anchor of navAnchors) {
    anchor.addEventListener('click', e => {
      e.preventDefault();

      anchor.blur(); // hide sub nav

      hideNav();

      const url = new URL(e.target.href);
      const target = $(url.hash);

      const options = {
        maxDuration: reducedMotion.matches ? 0 : 1500,
        speed: 500,
        easing: t => {
          return --t * t * t + 1;
        },
        verticalOffset: viewport.s.matches ? -120 : -90
      };

      animateScrollTo(target, options).then(hasScrolledToPosition => {
        if (hasScrolledToPosition) {
          target.setAttribute('tabindex', '-1');
          target.classList.add('focus-hidden');
          target.focus({ preventScroll: true });
          target.removeAttribute('tabindex');

          history.replaceState(null, null, url.hash);
        }
      });
    });
  }
}

export default init;
