import { $, $$ } from '../../helpers/query-selector';
import { viewport, reducedMotion } from '../../helpers/variables';
import animateScrollTo from 'animated-scroll-to';

const header = $('.js-header');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navMobile = $('.js-nav-mobile');

const initalActiveToggle = $('.js-nav-toggle-sublist.active');

const navIconActiveClass = 'c-nav-icon--active';
const navMobileActiveClass = 'l-nav__mobile-wrapper--active';
const headerClass = 'l-header--white';

let navVisible = false;

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);
  navMobile.classList.add(navMobileActiveClass);
  header.classList.add(headerClass);

  const activeNavLink = $('.js-nav-link.active');
  const activeTag = $('.js-nav-tag.active');
  if (activeNavLink && activeTag) activeNavLink.classList.remove('active');
}

function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);
  navMobile.classList.remove(navMobileActiveClass);
  header.classList.remove(headerClass);

  updateActiveSubList();
}

function updateActiveSubList() {
  if (initalActiveToggle) {
    const initialSublistId = initalActiveToggle.getAttribute('aria-controls');
    const initialActiveSublist = $('#' + initialSublistId);

    // Wait for nav wrapper transition
    setTimeout(function () {
      showSubList(initalActiveToggle, initialActiveSublist);
    }, 250);
  }
}

function closeActiveSubList() {
  const subListToggles = $$('.js-nav-toggle-sublist');
  const subLists = $$('.js-nav-sublist');

  for (const toggle of subListToggles) {
    if (toggle.classList.contains('active')) toggle.classList.remove('active');
  }

  for (const list of subLists) {
    if (list.classList.contains('visible')) list.classList.remove('visible');
  }
}

function hideSubList(subListToggle, subList) {
  subListToggle.setAttribute('aria-expanded', 'false');
  subListToggle.classList.remove('active');
  subList.classList.remove('visible');
}

function showSubList(subListToggle, subList) {
  closeActiveSubList();

  subListToggle.setAttribute('aria-expanded', 'true');
  subListToggle.classList.add('active');
  subList.classList.add('visible');

  // Scroll to active sublist
  scrollToSublist(subListToggle);
}

function scrollToSublist(subListToggle) {
  const options = {
    maxDuration: reducedMotion.matches ? 0 : 1500,
    speed: 500,
    easing: t => {
      return --t * t * t + 1;
    },
    verticalOffset: viewport.s.matches ? -120 : -90,
    elementToScroll: $('.js-nav__mobile-scroll-container')
  };

  animateScrollTo([null, subListToggle.offsetTop], options).then(
    hasScrolledToPosition => {
      if (hasScrolledToPosition) {
        subListToggle.setAttribute('tabindex', '-1');
        subListToggle.classList.add('focus-hidden');
        subListToggle.focus({ preventScroll: true });
        subListToggle.removeAttribute('tabindex');
      }
    }
  );
}

function init() {
  if (!navToggle) return;

  navToggle.addEventListener('click', () => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav conditions
  viewport.l.addEventListener('change', e => {
    if (e.matches) {
      hideNav();
    } else {
      updateActiveSubList();
    }
  });

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') hideNav();
  });

  // Init Sublist toggles
  const navCategories = $$('.js-nav-category', navMobile);

  for (const category of navCategories) {
    const subListToggle = $('.js-nav-toggle-sublist', category);
    if (!subListToggle) continue;

    const subList = $('.js-nav-sublist', category);

    subListToggle.addEventListener('click', () => {
      subList.classList.contains('visible')
        ? hideSubList(subListToggle, subList)
        : showSubList(subListToggle, subList);
    });
  }
}

export { init as default, navVisible, hideNav };
