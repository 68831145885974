import initNavMobile from './mobile';
import initNavDesktop from './desktop';
import initNavAnchors from './anchors';

function init() {
  initNavMobile();
  initNavDesktop();
  initNavAnchors();
}

export default init;
