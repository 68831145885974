import { $, $$ } from '../../helpers/query-selector';

const navDesktop = $('.js-nav-desktop');
const navCategories = $$('.js-nav-category', navDesktop);

function hideSubList(subListToggle, subList) {
  subListToggle.setAttribute('aria-expanded', 'false');
  if (!subListToggle.classList.contains('initially-active'))
    subListToggle.classList.remove('active');
  subList.classList.remove('visible');
}

function showSubList(subListToggle, subList) {
  // Align subList
  alignSublist(subListToggle, subList);

  const subLists = $$('.js-nav-sublist');

  for (const list of subLists) {
    if (list.classList.contains('visible')) list.classList.remove('visible');
  }

  subListToggle.setAttribute('aria-expanded', 'true');
  subListToggle.classList.add('active');
  subList.classList.add('visible');

  const activeNavLink = $('.js-nav-link.active');
  const activeTag = $('.js-nav-tag.active');
  if (activeNavLink && activeTag) activeNavLink.classList.remove('active');
}

function alignSublist(subListToggle, subList) {
  // Sublist position
  const toggleRect = subListToggle.getBoundingClientRect();

  // Regular position at center
  let left = subList.offsetWidth / -2 + toggleRect.width / 2;

  // Detect overflow
  const toggleCenter = toggleRect.left + toggleRect.width / 2;
  const subListWidth = subList.offsetWidth;
  const bodyWidth = document.body.offsetWidth; // window.innerHeight does not work b/c overflow

  const overflowRight = toggleCenter + subListWidth / 2;
  const overflowLeft = toggleCenter - subListWidth / 2;

  if (overflowRight >= bodyWidth) {
    const offset = overflowRight - bodyWidth + 5;
    subList.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  } else if (overflowLeft < 0) {
    const offset = overflowLeft - 5;
    subList.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  }

  subList.style.setProperty('left', left + 'px');
}

function init() {
  if (!navDesktop) return;

  for (const category of navCategories) {
    const subListToggle = $('.js-nav-toggle-sublist', category);
    if (!subListToggle) continue;

    const subList = $('.js-nav-sublist', category);

    // Hover behaviour
    subListToggle.addEventListener('mouseover', () => {
      showSubList(subListToggle, subList);
    });

    category.addEventListener('mouseleave', () => {
      if (subList.classList.contains('visible'))
        hideSubList(subListToggle, subList);
    });

    // Keydown behaviour
    subListToggle.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        subList.classList.contains('visible')
          ? hideSubList(subListToggle, subList)
          : showSubList(subListToggle, subList);
      }
    });
  }
}

export default init;
